import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../../axios/baseConfig";
import {
  AppointmentStatus,
  AppointmentStatusText,
  IAppointmentGetItemsResponse,
  IAppointmentItemResponse,
  ProblemType,
} from "../../types/appointment";

import dayjs from "dayjs";
import locale from "dayjs/plugin/localizedFormat";
import { onTransformAppointmentStatus, onTransformAppointmentType } from "../../helpers";
dayjs.extend(locale);

interface IChangeStatus {
  user_id: string;
  status: AppointmentStatus;
  statusText: AppointmentStatusText;
  message: string;
}

interface IChangeAppointment {
  type: ProblemType;
  phone: string;
  time: string;
  problem: string;
  status: AppointmentStatus;
  _id: string;
}

interface IDeleteItem {
  _id: string;
}

export interface IItemStateData {
  name: string;
  user_id: string;
  id: string;
  type: string;
  phone: string;
  time: string;
  problem: string;
  status: AppointmentStatus;
  statusText: AppointmentStatusText;
  message: string;
  createdAt: string;
}

interface IState {
  data: IItemStateData[];
  isLoading: boolean;
  isError: boolean;
}

const initialState: IState = {
  data: [],
  isLoading: false,
  isError: false,
};

export const thunkGetAllAppointments = createAsyncThunk("get/appointments", async (_, { dispatch, rejectWithValue }) => {
  const response = await axios
    .get<IAppointmentGetItemsResponse>("/admin/appointment/get-all")
    .then(({ data }) => {
      return onTransformAppointmentData(data.data);
    })
    .catch((error) => {
      return rejectWithValue("Непредвиденная ошибка");
    });

  return response as IItemStateData[];
});

export const appointmentSlice = createSlice({
  name: "appointment",
  initialState,
  reducers: {
    onChangeAppointmentStatus: (state, action: PayloadAction<IChangeStatus>) => {
      state.data = state.data.map((el) => {
        if (el.user_id === action.payload.user_id) {
          return { ...el, status: action.payload.status, statusText: action.payload.statusText, message: action.payload.message };
        }

        return el;
      });
    },

    onAddAppointment: (state, action: PayloadAction<IAppointmentItemResponse>) => {
      const appointment = onTransformAppointmentData([action.payload]);

      state.data = [...appointment, ...state.data];
    },

    onDeleteAppointment: (state, action: PayloadAction<IDeleteItem>) => {
      state.data = state.data.filter((el) => el.id !== action.payload._id);
    },

    onChangeAppointment: (state, action: PayloadAction<IAppointmentItemResponse>) => {
      state.data = state.data.map((el) => {
        if (el.id === action.payload._id) {
          const updateInfo = onTransformAppointmentData([action.payload])[0];

          return updateInfo;
        }

        return el;
      });
    },
  },
  extraReducers(builder) {
    builder
      .addCase(thunkGetAllAppointments.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(thunkGetAllAppointments.rejected, (state, action) => {
        state.isError = true;
      });
  },
});

export default appointmentSlice.reducer;
export const { onChangeAppointmentStatus, onAddAppointment, onDeleteAppointment, onChangeAppointment } = appointmentSlice.actions;

const onTransformAppointmentData = (appointments: IAppointmentItemResponse[]): IItemStateData[] => {
  const transformed: IItemStateData[] = appointments.map((el) => {
    return {
      name: `${el.first_name} ${el.last_name}`,
      user_id: el.user,
      id: el._id,
      type: onTransformAppointmentType(el.type),
      phone: 8 + el.phone,
      time: `${dayjs(el.date).locale("ru").format("D MMMM")} ${el.time}`,
      problem: el.problem,
      status: el.status,
      statusText: onTransformAppointmentStatus(el.status),
      message: el.message,
      createdAt: dayjs(el.createdAt).format("D MMMM"),
    };
  });

  return transformed;
};
