import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "../../axios/baseConfig";
import { IPost } from "../../types/post";

interface IGetAllPost {
  data: IPost[];
  message: string;
  success: boolean;
}

interface IState {
  posts: IPost[];
  isLoading: boolean;
  isError: boolean;
}

const initialState: IState = {
  posts: [],
  isLoading: false,
  isError: false,
};

export const onGetAllPosts = createAsyncThunk("get/posts", async () => {
  const { data } = await axios.get<IGetAllPost>("/post/get-all");

  return data;
});

export const onDeleteOnePost = createAsyncThunk("delete/post", async () => {});
export const onUpdateOnePost = createAsyncThunk("update/post", async () => {});
export const onCreateOnePost = createAsyncThunk("create/post", async () => {});

export const postSlice = createSlice({
  name: "post",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //GET ALL POSTS
      .addCase(onGetAllPosts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(onGetAllPosts.fulfilled, (state, action: PayloadAction<IGetAllPost>) => {
        state.posts = action.payload.data;
        state.isLoading = false;
      })
      .addCase(onGetAllPosts.rejected, (state) => {
        state.isLoading = false;
        state.isError = true;
      });
  },
});

export default postSlice.reducer;
