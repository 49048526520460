import { useEffect, useState } from "react";

import { Button, Modal, Popconfirm, Result, Table, Typography } from "antd";
import { ModalStatus } from "./ModalStatus";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { IItemStateData, thunkGetAllAppointments } from "../../redux/slices/appointmentSlice";
import { useAppSelector } from "../../hooks/useAppSelector";
import { AppointmentStatus, AppointmentStatusEnum } from "../../types/appointment";

export const Appointment = () => {
  const [isModalShowProblem, setIsModalShowProblem] = useState<boolean>(false);
  const [isModalChangeStatus, setIsModalChangeStatus] = useState<boolean>(false);

  const [appointment, setAppointment] = useState<IItemStateData>({
    name: "",
    user_id: "",
    problem: "",
    type: "other",
    status: "none",
    statusText: "Ошибка",
    phone: "",
    time: "",
    id: "",
    message: "",
    createdAt: "",
  });

  const dispatch = useAppDispatch();
  const appointmentData = useAppSelector((state) => state.appointment.data);
  const isLoading = useAppSelector((state) => state.appointment.isLoading);
  const isError = useAppSelector((state) => state.appointment.isError);

  useEffect(() => {
    dispatch(thunkGetAllAppointments());
  }, []);

  const onShowModalProblem = (appointment: IItemStateData) => {
    setIsModalShowProblem(true);
    setAppointment(appointment);
  };

  const onShowModalChange = (appointment: IItemStateData) => {
    setIsModalChangeStatus(true);
    setAppointment(appointment);
  };

  const onCloseModal = () => {
    setIsModalShowProblem(false);
    setIsModalChangeStatus(false);
  };

  if (isError) {
    return <Result status={500} title={500} subTitle={"Попробуйте обновить страницу"} />;
  }

  const colors = {
    accepted: "#87d068",
    rejected: "#f50",
    provided: "#00FF00",
    confirmed: "#1677ff",
    none: "white",
    error: "#f50",
  };

  const columns = [
    {
      title: "ФИО",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Тип",
      dataIndex: "type",
      key: "type",
      showOnResponse: false,
    },
    {
      title: "Телефон",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Время записи",
      dataIndex: "time",
      key: "time",
    },
    {
      title: "Проблема",
      dataIndex: "problem",
      key: "problem",
      onCell: (appointment: IItemStateData) => {
        return { onClick: () => onShowModalProblem(appointment) };
      },
      render: () => {
        return (
          <Button type="primary" size="small">
            Посмотреть
          </Button>
        );
      },
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "На рассмотрении",
          value: "accepted",
        },
        {
          text: "Подтверждено",
          value: "confirmed",
        },
        {
          text: "Отклонено",
          value: "rejected",
        },
        {
          text: "Услуга оказана",
          value: "provided",
        },
      ],
      onFilter: (value: any, appointment: IItemStateData) => {
        if (appointment.status === value) {
          return true;
        } else {
          return false;
        }
      },
      onCell: (appointment: IItemStateData) => {
        return {
          onClick: () => {
            if (appointment.status !== AppointmentStatusEnum.PROVIDED) {
              return onShowModalChange(appointment);
            }
          },
        };
      },
      render: (title: AppointmentStatus, appointment: IItemStateData) => {
        return (
          <Button style={{ backgroundColor: colors[title] }} type="primary" size="small">
            {appointment.statusText}
          </Button>
        );
      },
    },
  ];

  return (
    <>
      {isModalShowProblem && <ModalShowProblem appointment={appointment} onCloseModal={onCloseModal} />}
      {isModalChangeStatus && <ModalStatus appointment={appointment} onCloseModal={onCloseModal} />}
      <Table dataSource={appointmentData} pagination={{ position: ["bottomCenter"], pageSize: 11 }} columns={columns} />
    </>
  );
};

interface ModalShowProblemProps {
  appointment: IItemStateData;
  onCloseModal: (param: boolean) => void;
}

const ModalShowProblem: React.FC<ModalShowProblemProps> = ({ appointment, onCloseModal }) => {
  return (
    <Modal
      okText={"Ок"}
      cancelText={"Отмена"}
      footer={false}
      title={
        <>
          <Typography.Text>Проблема пользователя </Typography.Text>
          <Typography.Text type="success">{appointment.name}</Typography.Text>
          <Typography.Text> от {appointment.createdAt}</Typography.Text>
        </>
      }
      open={true}
      onOk={() => onCloseModal(false)}
      onCancel={() => onCloseModal(false)}
    >
      {appointment.problem}
    </Modal>
  );
};
