import { Modal, Typography } from "antd";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { thunkLogout } from "../../redux/slices/authSlice";

export const Logout = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const handleOk = () => {
    setIsModalOpen(false);
    dispatch(thunkLogout());
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (!isModalOpen) {
    return <Navigate to={"/"} />;
  }

  return (
    <>
      <Modal
        okText={"Да"}
        cancelText={"Отмена"}
        title="Вы действительно хотите выйти из учетной записи?"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Typography.Text type="success"> Все необходимые данные будут сохранены.</Typography.Text>
      </Modal>
    </>
  );
};
