import { AppointmentPage } from "../Pages/AppointmentPage/AppointmentPage";
import { HomePage } from "../Pages/Home/HomePage";
import { PostPage } from "../Pages/PostPage/PostPage";
import { Logout } from "../components/Logout/Logout";
import { FullPost } from "../components/post/FullPost/FullPost";

export interface IRouter {
  path: string;
  element: React.ComponentType;
}
export enum RoutesNames {
  HOME = "/",
  POSTPAGE = "/posts",
  FULLPOST = "/posts/:id",
  LOGOUT = "/logout",
  APPOINTMENT = "/appointment",
}

export const routes: IRouter[] = [
  { path: RoutesNames.HOME, element: HomePage },
  { path: RoutesNames.POSTPAGE, element: PostPage },
  { path: RoutesNames.FULLPOST, element: FullPost },
  { path: RoutesNames.APPOINTMENT, element: AppointmentPage },
  { path: RoutesNames.LOGOUT, element: Logout },
];
