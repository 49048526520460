import { useEffect } from "react";
import { useAppDispatch } from "../../../hooks/useAppDispatch";
import { onGetAllPosts } from "../../../redux/slices/postSlice";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Alert, Avatar, Card, Col, Divider, Result, Row, Spin } from "antd";

import square from "./assets/img/square.jpg";

import { EditOutlined, EllipsisOutlined, SettingOutlined } from "@ant-design/icons";
import Meta from "antd/es/card/Meta";
import { Link } from "react-router-dom";
import { Spinner } from "../../UI/Spinner/Spinner";

export const PostList = () => {
  const dispatch = useAppDispatch();
  const posts = useAppSelector((state) => state.post.posts);
  const isLoading = useAppSelector((state) => state.post.isLoading);
  const isError = useAppSelector((state) => state.post.isError);

  useEffect(() => {
    dispatch(onGetAllPosts());
  }, []);

  if (isLoading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <Row justify={"center"} align={"middle"}>
          <Result status="500" title="500" subTitle="Что-то пошло не так..." />
        </Row>
      </>
    );
  }

  return (
    <Row justify={{ xs: "center", md: "center", lg: "center", xl: "start", xxl: "start" }}>
      {posts.map((el) => {
        return (
          <Col style={{ margin: 10 }} key={el._id}>
            <Link to={el._id}>
              <Card
                style={{ width: 250, height: 250 }}
                cover={<img style={{ width: "100%", height: 150 }} src={el.img || square} />}
                actions={[<SettingOutlined key="setting" />, <EditOutlined key="edit" />, <EllipsisOutlined key="ellipsis" />]}
                bordered={true}
              >
                <Meta title={el.title} />
              </Card>
            </Link>
          </Col>
        );
      })}
      {posts.map((el) => {
        return (
          <Col style={{ margin: 10 }} key={el._id}>
            <Link to={el._id}>
              <Card
                style={{ width: 250, height: 250 }}
                cover={<img style={{ width: "100%", height: 150 }} src={el.img || square} />}
                actions={[<SettingOutlined key="setting" />, <EditOutlined key="edit" />, <EllipsisOutlined key="ellipsis" />]}
                bordered={true}
              >
                <Meta title={el.title} />
              </Card>
            </Link>
          </Col>
        );
      })}
    </Row>
  );
};
