import { Route, Routes } from "react-router-dom";
import { Auth } from "../components/Auth/Auth";

export const AppPublicRouter: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<Auth />} />
      <Route path="*" element={<Auth />} />
    </Routes>
  );
};
