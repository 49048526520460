import { useNavigate } from "react-router-dom";
import {
  LineChartOutlined,
  HomeOutlined,
  FileAddOutlined,
  EditOutlined,
  ContactsOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import type { MenuProps } from "antd";

import { Col, Divider, Menu, Row } from "antd";

import { RoutesNames } from "../../routers";

import { MenuInfo } from "rc-menu/lib/interface";

type MenuItem = Required<MenuProps>["items"][number];

function getItem(label: React.ReactNode, key: React.Key, icon?: React.ReactNode, children?: MenuItem[], type?: "group"): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const items: MenuItem[] = [
  getItem("Домашняя", "/", <HomeOutlined />),
  getItem("Добавить статью", "/add-post", <FileAddOutlined />),
  getItem("Статьи", RoutesNames.POSTPAGE, <EditOutlined />),
  getItem("Статистика", "/statistics", <LineChartOutlined />),
  getItem("Пользователи", "/users", <UsergroupAddOutlined />),
  getItem("Обращения", RoutesNames.APPOINTMENT, <ContactsOutlined />),
  getItem("Выйти", RoutesNames.LOGOUT, <LogoutOutlined />),
];

export const Aside: React.FC = () => {
  const navigate = useNavigate();

  const onSelectMenuItem = (info: MenuInfo) => {
    navigate(info.key);
  };

  return (
    <Col xs={{ span: 4 }} span={8}>
      <Menu onSelect={onSelectMenuItem} inlineCollapsed={false} items={items} />
    </Col>
  );
};
