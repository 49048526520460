import { Route, Routes } from "react-router-dom";
import { IRouter, routes } from ".";

export const AppPrivetRouter: React.FC = () => {
  return (
    <Routes>
      {routes.map((el: IRouter) => (
        <Route key={el.path} path={el.path} element={<el.element />} />
      ))}
    </Routes>
  );
};
