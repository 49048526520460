import { Alert, Button, Card, Col, Form, Input, Row, Typography } from "antd";

import { thunkGetAuth } from "../../redux/slices/authSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useAppSelector } from "../../hooks/useAppSelector";

import { IAuthParams } from "../../types/auth";

import "./Auth.scss";

export const Auth: React.FC = () => {
  const message = useAppSelector((state) => state.auth.message);
  const isLoading = useAppSelector((state) => state.auth.isLoading);
  const isError = useAppSelector((state) => state.auth.isError);

  const dispatch = useAppDispatch();

  const onFinish = (values: IAuthParams) => {
    dispatch(thunkGetAuth(values));
  };

  return (
    <div className="auth__wrapper">
      <Card>
        <Form name="basic" onFinish={onFinish} autoComplete="on">
          <Typography.Text strong>Логин</Typography.Text>
          <Form.Item hasFeedback name="email" rules={[{ required: true, message: "Введите логин" }]}>
            <Input />
          </Form.Item>

          <Typography.Text strong>Пароль</Typography.Text>
          <Form.Item
            hasFeedback
            name="password"
            rules={[
              { required: true, message: "Введите пароль" },
              { max: 20, min: 5, message: "5 - 20 символов" },
            ]}
          >
            <Input.Password autoComplete="on" />
          </Form.Item>

          <Form.Item>
            <Button loading={isLoading} type="primary" htmlType="submit">
              Войти
            </Button>
          </Form.Item>
        </Form>{" "}
        {isError && <Alert message={message ? message : "Произошла непредвиденная ошибка"} type="error" showIcon />}
      </Card>{" "}
    </div>
  );
};
