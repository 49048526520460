import axios from "axios";
import { IResponseAuth } from "../types/auth";
import { BACKEND_URL } from "../http/vars";

const instanse = axios.create({
  baseURL: `${BACKEND_URL}`,
  withCredentials: true,
});

instanse.interceptors.request.use((config) => {
  config.headers.authorization = `Bearer ${window.localStorage.getItem("accessToken")}` || "";
  return config;
});

instanse.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      try {
        originalRequest._isRetry = true; //чтобы не зациклить, если опять прилетел 401

        const res = await axios.get<IResponseAuth>(`${BACKEND_URL}/auth/refresh/email`, { withCredentials: true });

        window.localStorage.setItem("accessToken", res.data.tokens.accessToken);

        return instanse.request(originalRequest);
      } catch (error) {
        window.localStorage.removeItem("accessToken");

        console.log("Ошибка обновления токена");
      }
    }
    throw error;
  }
);

export default instanse;
