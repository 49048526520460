export interface IAppointmentItemResponse {
  first_name: string;
  last_name: string;
  user: string;
  problem: string;
  type: ProblemType;
  status: AppointmentStatus;
  _id: string;
  time: string;
  date: string;
  phone: string;
  message: string;
  createdAt: string;
}

export interface IAppointmentGetItemsResponse {
  data: IAppointmentItemResponse[];
  status: number;
  message: string;
  error: null;
}

export enum AppointmentStatusEnum {
  NONE = "none",
  ERROR = "error",
  ACCEPTED = "accepted",
  REJECTED = "rejected",
  PROVIDED = "provided",
  CONFIRMED = "confirmed",
}

export type AppointmentStatus = "none" | "error" | "accepted" | "confirmed" | "rejected" | "provided";
export type AppointmentStatusText = "На рассмотрении" | "Подтверждено" | "Отклонено" | "Услуга оказана" | "Ошибка";
export type ProblemType = "civil" | "criminal" | "other";
