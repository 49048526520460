import { Alert, Row, Typography } from "antd";

export const HomePage = () => {
  return (
    <>
      {/* <Alert
        showIcon={false}
        style={{ width: "100%", height: "90px", textAlign: "center" }}
        banner
        message={<Typography.Title level={5}>Административная панель Юридической Клинки при ЕГУ им. И.А. Бунина</Typography.Title>}
      /> */}
    </>
  );
};
