import { useEffect } from "react";
import { useAppDispatch } from "../../hooks/useAppDispatch";

import { BACKEND_URL } from "../../http/vars";
import { IAppointmentItemResponse } from "../../types/appointment";
import { onAddAppointment, onChangeAppointment, onDeleteAppointment } from "../../redux/slices/appointmentSlice";
import { message } from "antd";

enum EventType {
  REGISTER = "register",
  DELETE = "delete",
  CHANGE = "change",
}

interface IAppointmentEventResponse {
  doc: IAppointmentItemResponse;
  event: string;
}

export const AppointmentConnection = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const eventSource = new EventSource(`${BACKEND_URL}/admin/appointment/connect`, {
      withCredentials: true,
    });

    eventSource.onmessage = (event) => {
      const response: IAppointmentEventResponse = JSON.parse(event.data);

      if (response.event === EventType.REGISTER) {
        onShowSuccessMessage(15, `Получено заявление от ${response.doc.first_name} ${response.doc.last_name}`);
        return dispatch(onAddAppointment(response.doc));
      }

      if (response.event === EventType.DELETE) {
        onShowWarningMessage(15, `${response.doc.first_name} ${response.doc.last_name} удалил свое заявление`);
        return dispatch(onDeleteAppointment(response.doc));
      }
      if (response.event === EventType.CHANGE) {
        onShowWarningMessage(15, `${response.doc.first_name} ${response.doc.last_name} изменил свое заявление`);
        return dispatch(onChangeAppointment(response.doc));
      }
    };

    eventSource.onerror = (error) => {
      console.log(error);

      return onShowErrorMessage(1800, "Real-time соединение было прекращено");
    };

    eventSource.onopen = () => {
      return onShowSuccessMessage(3, "Real-time соединение было установлено");
    };

    return () => {
      console.log("close connection");
      eventSource.close();
    };
  }, []);

  const [messageApi, contextHolder] = message.useMessage({ maxCount: 1 });

  const onShowSuccessMessage = (duration: number = 5, message: string = "") => {
    messageApi.open({
      type: "success",
      content: message,
      duration,
    });
  };

  const onShowErrorMessage = (duration: number = 5, message: string = "") => {
    messageApi.open({
      type: "error",
      content: message,
      duration,
    });
  };

  const onShowWarningMessage = (duration: number = 5, message: string = "") => {
    messageApi.open({
      type: "warning",
      content: message,
      duration,
    });
  };

  console.log("connect");

  return <> {contextHolder}</>;
};
