import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAuthParams, IResponseAuth } from "../../types/auth";

import axios from "../../axios/baseConfig";
import defaultAxios from "axios";

import { IAdminProfile } from "../../types/admin";
import { AxiosError, AxiosResponse } from "axios";
import { BACKEND_URL } from "../../http/vars";

interface IResponseAuthError {
  data: null;
  message: string;
}

interface IState {
  isAuth: boolean;
  isError: boolean;
  isLoading: boolean;
  isConfirming: boolean;
  message: string;
}

const initialState: IState = {
  isAuth: false,
  isError: false,
  isLoading: false,
  isConfirming: false,
  message: "",
};

export const thunkGetAuth = createAsyncThunk("get/auth", async (data: IAuthParams, { rejectWithValue, dispatch }) => {
  const response = await axios
    .post<IResponseAuth>("/admin/auth/login", data, {
      withCredentials: true,
    })
    .then(({ data }) => {
      window.localStorage.setItem("accessToken", data.tokens.accessToken);

      return data;
    })
    .catch((error: AxiosError<IResponseAuthError>) => {
      return rejectWithValue(error.response?.data.message || "Ошибка");
    });

  return response;
});

export const thunkCheckAuth = createAsyncThunk("get/access", async (_, { rejectWithValue }) => {
  const response = await defaultAxios
    .get<IResponseAuth>(`${BACKEND_URL}/auth/refresh/email`, { withCredentials: true })
    .then(({ data }) => {
      localStorage.setItem("accessToken", data.tokens.accessToken);

      return data;
    })
    .catch(() => {
      return rejectWithValue("");
    });

  return response;
});

export const thunkLogout = createAsyncThunk("get/logout", async (_, { rejectWithValue }) => {
  const response = await axios
    .get("/auth/logout/email")
    .then(({ data }) => {
      window.localStorage.removeItem("accessToken");

      return data;
    })
    .catch(() => {
      return rejectWithValue("");
    });

  return response;
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunkGetAuth.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(thunkGetAuth.fulfilled, (state) => {
        state.isAuth = true;
        state.isLoading = false;
      })
      .addCase(thunkGetAuth.rejected, (state, action) => {
        state.isError = true;
        state.isLoading = false;
        state.message = action.payload as string;
      })
      //check
      .addCase(thunkCheckAuth.pending, (state) => {
        state.isConfirming = true;
      })
      .addCase(thunkCheckAuth.fulfilled, (state) => {
        state.isAuth = true;
        state.isConfirming = false;
      })
      .addCase(thunkCheckAuth.rejected, (state) => {
        state.isError = true;
        state.isConfirming = false;
        state.isAuth = false;
        state.message = "Доступ к профилю не был получен";
      })
      //logout
      .addCase(thunkLogout.fulfilled, (state) => {
        state.isAuth = false;
      });
  },
});

export default authSlice.reducer;
