import { AppointmentStatus, ProblemType } from "../types/appointment";

export const onTransformAppointmentStatus = (status: AppointmentStatus) => {
  switch (status) {
    case "accepted":
      return "На рассмотрении";

    case "confirmed":
      return "Подтверждено";

    case "provided":
      return "Услуга оказана";

    case "rejected":
      return "Отклонено";

    default:
      return "Ошибка";
  }
};

export const onTransformAppointmentType = (type: ProblemType) => {
  switch (type) {
    case "civil":
      return "Гражданское";

    case "criminal":
      return "Уголовное";

    case "other":
      return "Иное";

    default:
      return "Не указано";
  }
};
