import { Col, Row } from "antd";
import { Appointment } from "../../components/Appointment/Appointment";

export const AppointmentPage = () => {
  return (
    <Row style={{ width: "100%" }}>
      <Col span={24}>
        <Appointment />
      </Col>
    </Row>
  );
};
