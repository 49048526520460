import { useParams } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import SimpleMDE from "react-simplemde-editor";
import axios from "../../../axios/baseConfig";
import { Categories, IPost } from "../../../types/post";

import "easymde/dist/easymde.min.css";
import { Card, Col, Divider, Input, Result, Row, Select, Spin } from "antd";
import Title from "antd/es/typography/Title";
import { Spinner } from "../../UI/Spinner/Spinner";

interface IResponse {
  message: string;
  data: IPost;
  success: boolean;
}

const initialState = {
  title: "",
  text: "",
  img: "",
  tags: [],
  type: Categories.OTHER,
  сity: "",
  initiator: "",
  _id: "",
};

export const FullPost: React.FC = () => {
  const [state, setState] = useState<IPost>(initialState);
  const [isError, setIsError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { id } = useParams();

  useEffect(() => {
    onGetPostById();
  }, []);

  const onGetPostById = async () => {
    try {
      const { data } = await axios.get<IResponse>(`/post/${id}`);

      setState(data.data);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsError(true);
    }
  };

  if (isLoading) {
    return (
      <>
        <Spinner />
      </>
    );
  }

  if (isError) {
    return (
      <>
        <Row justify={"center"} align={"middle"}>
          <Result status="500" title="500" subTitle="Что-то пошло не так..." />
        </Row>
      </>
    );
  }

  console.log(state);

  return (
    <>
      <Row justify={"start"}>
        <Row>
          <Col span={24}>
            <img width={500} src={state.img} alt="" />
          </Col>
        </Row>
        <Row>
          <Col span={8}>
            <Card title={"Заголовок Статьи"} style={{ width: 300 }}>
              <Input value={state.title} />
            </Card>
          </Col>
          <Col span={8}>
            <Card title={"Теги"} style={{ width: 300 }}>
              <Input value={state.tags} />
            </Card>
          </Col>
          <Col span={8}>
            <Card title={"Отрасль"} style={{ width: 300 }}>
              <Select
                defaultValue="lucy"
                style={{ width: 120 }}
                options={[
                  { value: "jack", label: "Гражданское право" },
                  { value: "lucy", label: "Уголовное право" },
                  { value: "Yiminghe", label: "Административное право" },
                  { value: "disabled", label: "Иное" },
                ]}
              />
            </Card>
          </Col>
        </Row>
      </Row>

      <Col>
        <SimpleMDE value={state.text} />
      </Col>
    </>
  );
};
