import { Alert, Button, Divider, Form, Input, Modal, Row, Select, Space, Typography } from "antd";
import { IItemStateData, onChangeAppointmentStatus } from "../../redux/slices/appointmentSlice";
import { useState } from "react";
import { AppointmentStatus, AppointmentStatusEnum } from "../../types/appointment";

import axios from "../../axios/baseConfig";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { onTransformAppointmentStatus } from "../../helpers";

interface IModelStatus {
  appointment: IItemStateData;
  onCloseModal: () => void;
}

interface IForm {
  message: string;
}

export const ModalStatus: React.FC<IModelStatus> = ({ onCloseModal, appointment }) => {
  const [status, setStatus] = useState<AppointmentStatus>("none");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [isDisabledButton, setIsDisabledButton] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const onChangeUserStatus = async (form?: IForm) => {
    try {
      const data = {
        status,
        user_id: appointment.user_id,
        message: status === AppointmentStatusEnum.REJECTED ? form?.message : "",
      };
      setIsLoading(true);

      const response = await axios.patch("/admin/appointment/change-status", data);

      dispatch(
        onChangeAppointmentStatus({
          user_id: appointment.user_id,
          status: status,
          statusText: onTransformAppointmentStatus(status),
          message: form?.message || "",
        })
      );

      onCloseModal();
      setIsLoading(false);
    } catch (error) {
    } finally {
      setIsLoading(false);
      setIsError(true);
    }
  };

  const select =
    appointment.status !== AppointmentStatusEnum.REJECTED ? (
      <Select
        placeholder="Статус обращения"
        onSelect={(status: AppointmentStatus) => setStatus(status)}
        optionFilterProp="children"
        onChange={() => setIsDisabledButton(false)}
        options={[
          {
            value: "confirmed",
            label: "Подтвердить обращение",
            disabled: appointment.status !== AppointmentStatusEnum.ACCEPTED,
          },
          {
            value: "rejected",
            label: "Отказать в обращении",
            disabled: appointment.status === AppointmentStatusEnum.CONFIRMED,
          },
          {
            value: "provided",
            label: "Услуга была оказана",
            disabled: appointment.status === AppointmentStatusEnum.ACCEPTED,
          },
        ]}
      />
    ) : null;

  const rejected =
    appointment.status === AppointmentStatusEnum.REJECTED ? (
      <>
        <Space direction="vertical">
          <Typography.Text strong type="danger">
            Причина отказа:
          </Typography.Text>
          <Alert banner showIcon={false} type="error" message={`${appointment.message}`} />
        </Space>
      </>
    ) : null;

  const rejctedContent = status === AppointmentStatusEnum.REJECTED && appointment.status !== AppointmentStatusEnum.REJECTED && (
    <>
      <Divider>
        <Row justify={"center"}>
          <Typography.Text strong type="danger">
            Укажите причину отказа
          </Typography.Text>
        </Row>
      </Divider>
      <Form onFinish={onChangeUserStatus} name="basic">
        <Form.Item
          name="message"
          rules={[
            { required: true, message: "Заполните поле" },
            { min: 3, message: "Не менее 3 символов" },
            { max: 50, message: "Не более 50 символов" },
          ]}
        >
          <Input />
        </Form.Item>
        <Row justify={"center"}>
          <Button loading={isLoading} danger type="primary" htmlType="submit">
            Отправить
          </Button>
        </Row>
      </Form>
    </>
  );

  const button = status !== AppointmentStatusEnum.REJECTED && appointment.status !== AppointmentStatusEnum.REJECTED && (
    <Row justify={"center"}>
      <Divider />
      <Button disabled={isDisabledButton} loading={isLoading} onClick={() => onChangeUserStatus()} type="primary">
        Отправить
      </Button>
    </Row>
  );

  return (
    <Modal footer={false} title={"Изменение статуса заявления "} onCancel={() => onCloseModal()} open={true}>
      <Typography.Text strong>Выберите статус заявления пользователя </Typography.Text>
      <Typography.Text type="success" strong>
        {appointment.name}
      </Typography.Text>
      <Divider />

      {select}
      {rejected}
      {button}
      {rejctedContent}

      {isError && (
        <Divider>
          <Alert banner showIcon type="error" message={"Произошла ошибка"} />
        </Divider>
      )}
    </Modal>
  );
};
