import { Col, Row, Spin } from "antd";

import { Aside } from "../Aside/Aside";

import "./App.scss";
import { useAppSelector } from "../../hooks/useAppSelector";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { useEffect } from "react";
import { thunkCheckAuth } from "../../redux/slices/authSlice";
import { AppPrivetRouter } from "../../routers/AppPrivetRouter";
import { AppPublicRouter } from "../../routers/AppPublicRouter";

import dayjs from "dayjs";
import "dayjs/locale/ru";
import { AppointmentConnection } from "../Appointment/AppointmentConnection";
dayjs.locale("ru");

function App() {
  const isAuth = useAppSelector((state) => state.auth.isAuth);
  const isConfirming = useAppSelector((state) => state.auth.isConfirming);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (window.localStorage.getItem("accessToken")) {
      dispatch(thunkCheckAuth());
    }
  }, []);

  if (isConfirming) {
    return (
      <>
        <Row style={{ height: "100%" }} justify={"center"} align={"middle"}>
          <Spin size="large" />
        </Row>
      </>
    );
  }

  const content = isAuth ? (
    <Row justify={{ xs: "center", sm: "center", md: "center", lg: "center", xl: "start", xxl: "start" }}>
      <Aside />
      <Col xs={{ span: 20 }} span={16}>
        <Row>
          <AppPrivetRouter />
        </Row>
      </Col>
    </Row>
  ) : (
    <>
      <AppPublicRouter />
    </>
  );

  return (
    <div className="App">
      {content}
      {isAuth ? <AppointmentConnection /> : null}
    </div>
  );
}

export default App;
